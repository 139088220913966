import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "detailRows" ]

  initialize() {
    if (this.detailRowsTarget.childElementCount >= 6) {
      this.element.classList.add("two-row-design");
    }
  }

}