import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["notifications"];

  connect() {
    this.updateNotificationsCounter();
  }

  updateNotificationsCounter() {
    this.fetchNotificationsCounter();

    this.refreshTimer = setInterval(() => {
      this.fetchNotificationsCounter();
    }, 20000);
  }

  disconnect() {
    this.stopRefreshing();
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  fetchNotificationsCounter() {
    const bellEl = document.querySelector("#notification-bell-el");
    const counter = document.querySelector("#notification-counter-el");

    fetch("/notifications_counter").then((response) => {
      response.json().then((data) => {
        if (data["count"] > 0) {
          counter.innerText = data["count"];
          counter.style.display = "static";
          if (bellEl.classList.contains('animate-slow-pulse')) return;
          bellEl.classList.add('animate-slow-pulse');
        } else {
          counter.innerText = data["count"];
        }
      });
    });
  }

  close() {
    if (this.hasNotificationsTarget) {
      this.notificationsTarget.remove();
    }
  }
}
