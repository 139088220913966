import ApplicationController from "./application_controller";
import { ConfirmModal } from "../utils/swal.js";
import { fetchModal } from "../utils/fetch_modal";

export default class extends ApplicationController {
  static targets = ["templateStatusBtn", "expandMessageBtn"];
  static values = {
    mls: String,
    selector: String,
    searchIdSelector: String
  };

  confirmMessageStatusToggle(e) {
    e.preventDefault();

    const templateActive = this.templateStatusBtnTarget.getAttribute("data-message-active");
    const resourceType = this.templateStatusBtnTarget.getAttribute("data-resource-type");
    const htmlMsg = templateActive == "true" ? "Archived" : "Active";
    
    ConfirmModal.fire({
      title: "Are you sure?",
      html: `This will mark this ${this.toText(resourceType)} as \'${htmlMsg}\'.`,
      icon: "question",
    }).then((result) => {
      if (result.value) return this.stimulate("MessageReflex#toggle", this.templateStatusBtnTarget);
      setTimeout(() => {
        // Unfocuses and resets the value since it was canceled
        this.templateStatusBtnTarget.blur();
        e.target.checked ? e.target.checked = false : e.target.checked = true
      }, 500);
    });
  }

  toText(text) {
    return text.replace(/\.?([A-Z])/g, function (x,y){return " " + y.toLowerCase()}).trim()
  }

  expandMessageContent(e) {
    const messageId = this.expandMessageBtnTarget.getAttribute("data-id");
    const messageType = this.expandMessageBtnTarget.getAttribute("data-type");
    const resourceType = this.expandMessageBtnTarget.getAttribute("data-resource");

    const url = `expand_${messageType}/${messageId}?resource_type=${resourceType}`;

    fetchModal(e, url);
  }

  chooseMLSName(e) {
    e.preventDefault();
    const searchResults = document.querySelector(
      this.searchIdSelectorValue
    )
    const inputElement = document.querySelector(
      this.selectorValue ? this.selectorValue : '#message_template_mls_name'
    );
    const value = this.mlsValue;

    inputElement.value = value;
    searchResults.innerHTML = "";
  }
}
