import ApplicationController from "./application_controller";
import { ConfirmModal } from "../utils/swal.js";
import { fetchModal } from "../utils/fetch_modal";

export default class extends ApplicationController {
  static targets = ["note", "submitBtn"];

  createNote(e) {
    e.preventDefault();

    const expTransactionUuid = this.noteTarget.getAttribute("data-uuid");
    const uuids = this.noteTarget.getAttribute("data-transactions-uuids");
    const url = `/exp_transactions/${expTransactionUuid}/notes/new?uuids=${uuids}`;
    fetchModal(e, url);
  }

  editNote(e) {
    e.preventDefault();

    const expTransactionUuid = this.noteTarget.getAttribute("data-uuid");
    const uuids = this.noteTarget.getAttribute("data-transactions-uuids");
    const id = this.noteTarget.getAttribute("data-id");
    const url = `/exp_transactions/${expTransactionUuid}/notes/${id}/edit?uuids=${uuids}`;
    fetchModal(e, url);
  }

  // INFO: Simulates a success reflex. There are some edge cases where I could see this being problematic, like if it did fail to save in the DB. But overall, should do the trick.
  updateAndAddNote() {
    if (document.querySelector("#note-text-field").value.length == 0) return;
    
    // Close Modal to simulate the refresh
    document.querySelectorAll(".modal").forEach((modal) => {
      modal.firstElementChild.nextElementSibling.classList.add(
        "animate__zoomOutDown"
      );
      setTimeout(() => {
        modal.remove();
      }, 1000);
    });
  }

  confirmRemove(e) {
    e.preventDefault();

    ConfirmModal.fire({
      title: "Are you sure?",
      html: `This will remove this note from this Transaction.`,
      icon: "warning",
    }).then((result) => {
      if (result.value) {
        this.stimulate("ExpTransactionReflex#remove");
        this.removeSuccess();
        return;
      }
      super.unfocusTarget(this.noteTarget);
    });
  }
}
