import ApplicationController from "./application_controller";
import { fetchModal } from "../utils/fetch_modal";

export default class extends ApplicationController {
  static targets = ["notification", "submitBtn"];

  createNotification(e) {
    e.preventDefault();

    const expTransactionUuid = this.notificationTarget.getAttribute("data-uuid");
    const parentNotificationUuid = this.notificationTarget.getAttribute("data-parent-notification-uuid");
    const url = `/exp_transactions/${expTransactionUuid}/one_off_notifications/new?parent_notification_uuid=${parentNotificationUuid}`;
    fetchModal(e, url);
  }

  sendMessage(e) {
    const actionText = this.submitBtnTarget.innerText;
    // Close Modal to simulate the refresh
    document.querySelectorAll(".modal").forEach((modal) => {
      modal.firstElementChild.nextElementSibling.classList.add(
        "animate__zoomOutDown"
      );
      setTimeout(() => {
        modal.remove();
      }, 1000);
    });
  }
}
