import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "targetCheckbox" ]

  updateCheckboxes(e) {
    if (e.currentTarget.checked == true) {
      document.querySelector(`#ids_to_merge_[value="${e.currentTarget.value}"]`).checked = true;
      this.targetCheckboxTargets.forEach((checkbox) => {
        if (e.currentTarget !== checkbox) checkbox.setAttribute("disabled", true);
      })
    }
    if (e.currentTarget.checked == false) {
      this.targetCheckboxTargets.forEach((checkbox) => {
        if (checkbox.dataset.isDisabled !== "true") checkbox.removeAttribute("disabled");
      })
      
    }
  }
  validateCheckboxSelection(e) {
    const hasMerge = document.querySelectorAll(".merge-checkboxes:checked").length >= 1;
    const hasTarget = document.querySelectorAll(".target-checkboxes:checked").length >= 1;

    if (!hasMerge || !hasTarget) {
      e.preventDefault(); 
      super.showDangerToast("Please select a user to merge and a target");
    } 
  }
}