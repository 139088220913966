import ApplicationController from "./application_controller";
import consumer from "../channels/consumer";

export default class extends ApplicationController {
  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "NotificationChannel" },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    if (data.message) {
      const el = document.querySelector(
        data.email_type ? 
        `#email-messages-list-container-${data.trx_id}` :
        `#sms-messages-list-container-${data.trx_id}`
      )
      if (el) el.insertAdjacentHTML("afterbegin", data.message);
    }
  }
}
