import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["container", "sortBtn"];

  static values = {
    mls: String,
  };

  show(e) {
    e.preventDefault();
    const sortBtn = this.sortBtnTarget;
    const sortContainer = this.containerTarget;
    sortContainer.classList.remove("vanish");
    sortContainer.classList.remove("animate__flipOutX");
    if (!this.data.has('has-dismiss-click-listener')) { 
      this.listenForDismissClick(sortContainer)
    }
  }

  listenForDismissClick(sortContainer) {
    this.data.set("has-dismiss-click-listener", true);
    document.addEventListener('click', (e) => {
      if (!this.element.contains(e.target)) {
        sortContainer.classList.add("animate__flipOutX");
        setTimeout(() => {
          sortContainer.classList.add("vanish")
        }, 800);
      }
    })
  }

  searchMlsStatus() {
    const container = document.querySelector("#mls-status-search-results");
    if (!this.data.has('has-dismiss-click-listener')) { 
      this.data.set("has-dismiss-click-listener", true);
      document.addEventListener('click', (e) => {
        if (!this.element.contains(e.target)) {
          container.classList.add("vanish")
        }
      })
    }
    const usState = document.querySelector("#us_state").value;
    this.stimulate("ExpTransactionReflex#search_mls_status", usState);
  }

  chooseMLSStatus(e) {
    e.preventDefault();
    const searchResults = document.querySelector(
      "#mls-status-search-results"
    );
    const inputElement = document.querySelector(
      "#mls-original-status-field"
    );
    const value = this.mlsValue;

    inputElement.value = value;
    searchResults.innerHTML = "";
  }
}
