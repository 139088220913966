// Allows dropdowns to be opened and dismissed
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdownBtn", "dropdown", "dropdownMenu" ]

  toggleDisplay() {
    this.dropdownTarget.classList.toggle('is-open'); 
    this.dropdownMenuTarget.classList.remove("animate__flipOutX");
    this.dropdownMenuTarget.parentElement.classList.remove("animate__flipOutX");
    if (!this.data.has('has-dismiss-click-listener')) this.listenForDismissClick(this.dropdownMenuTarget)
  
  }
  
  hide() {
    this.dropdownTarget.classList.remove('is-open');
  }

  listenForDismissClick(menu) {
    this.data.set("has-dismiss-click-listener", true);
    document.addEventListener('click', (e) => {
      if (!this.element.contains(e.target)) {
        menu.parentElement.classList.add("animate__flipOutX");
        setTimeout(() => {
          this.hide();
        }, 500);
      }
    })
  }
}