import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["filterToggle", "showMoreBtn"]
  static values = {
    stimulate: String
  }

  initialize() {
    this.searchInput = document.querySelector("#search-input");
  }

  afterReflex() {
    if (this.searchInput) {
      document.querySelector("form")?.setAttribute('data-query', this.searchQuery)
    }
    const dataParamsHash = document.querySelector("#params-hash")?.getAttribute('data-params-hash')
    if (this.searchInput) {
      this.searchInput.setAttribute("data-params-hash", dataParamsHash);
    }
  }

  showSpinnerAndReflex() {
    this.showMoreBtnTarget.classList.add('show-spinner');
    this.stimulate(this.stimulateValue, this.showMoreBtnTarget).then(() => {
      this.showMoreBtnTarget.classList.remove('show-spinner');
    });
  }

  removeMlsName() {
    this.stimulate("FilterReflex#remove_display_mls_name_filter_field")
  }

  displayMlsNameFilterField() {
    const showResolved = document.querySelector("#filter-resolved-check-box-field").checked;
    this.stimulate("FilterReflex#display_mls_name_filter_field", showResolved)
  }

  checkMlsName() {
    const showResolved = document.querySelector("#filter-resolved-check-box-field").checked;
    const archivedCheckBox = document.querySelector("#filter-archived-check-box-field");

    if (showResolved) {
      archivedCheckBox.classList.add('disabled')
      archivedCheckBox.setAttribute('disabled', 'disabled')
    } else {
      archivedCheckBox.classList.remove('disabled')
      archivedCheckBox.removeAttribute('disabled')
    }


    this.stimulate("FilterReflex#check_mls_name", showResolved)
  }

  get searchQuery() {
    return this.searchInput.value;
  }

  toggleStatsFilter() {
    const dataFilterType = this.filterToggleTarget.getAttribute("data-filter-type");
    const newAttr = dataFilterType === "brokers" ? "agents" : "brokers"
    const title = document.getElementById("page-title")

    title.textContent = dataFilterType === "brokers" ? "Agent Activity" : "Broker Activity" // Change page title depending on switch toggle
    this.filterToggleTarget.setAttribute("data-filter-type", newAttr);

    this.searchInput.value = ""
    document.querySelector("#us_state").value = ""

    this.stimulate("StatsReflex#choose_stats");
    
    const filterAction = this.data.get("type");
    this.stimulate(`FilterReflex#${filterAction}`);
  }

  toggleResolvedCheckBox() {
    const resolvedCheckBox = document.querySelector("#filter-resolved-check-box-field");
    const archivedCheckBoxChecked = document.querySelector("#filter-archived-check-box-field").checked;
    if (archivedCheckBoxChecked) {
      resolvedCheckBox.classList.add('disabled')
      resolvedCheckBox.setAttribute('disabled', 'disabled')
    } else {
      resolvedCheckBox.classList.remove('disabled')
      resolvedCheckBox.removeAttribute('disabled')
    }
  }
}
