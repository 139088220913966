import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "detailsComponent" ]
  static values = { isOpen: Boolean }

  toggleTrxDetails(e) {
    if (e.target != e.currentTarget) {
      if (e.target.getAttribute("role") != "gridcell") {
        return true;
      }
    } 
    this.isOpenValue = !this.isOpenValue;
    this.element.classList.toggle("is-open");
    this.handleAnimation(); 
  }

  handleAnimation() {
    if (this.isOpenValue) {
      this.detailsComponentTarget.classList.add("expand");
      this.detailsComponentTarget.classList.remove("minimize");
    } else {
      this.detailsComponentTarget.classList.remove("expand");
      this.detailsComponentTarget.classList.add("minimize");
      setTimeout(() => {
        this.detailsComponentTarget.classList.remove("minimize");
      }, 300);
    }
  }

}