import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["menu", "container"];

  showColSettings() {
    fetch("/trx_col_settings")
    .then((r) => r.text())
    .then((html) => document.querySelector("#column-settings-placeholder").innerHTML = html)
    .then(() => {
        document.addEventListener('click', (e) => {
          if (!this.element.contains(e.target)) {
            if (this.containerTarget.firstElementChild) this.containerTarget.firstElementChild.remove();
          }
        })
    });
  }

  updateColumns(e) {
    let size = 0;
    this.element.querySelectorAll('input').forEach((input) => {
      if (input.checked ) size += 1;
    });
    if (size == 9) { e.target.checked = false }
    document.querySelector("#exp-transactions-list").dataset.gridSize = size == 9 ? 8 : size;
  }
}
