import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    user: Object,
    randNum: String,
  };

  chooseUser(e) {
    e.preventDefault();
    const searchResults = document.querySelector(
      `#user-name-search-results-${this.randNumValue}`
    )

    const nameInput = document.querySelector(`#user-name-input-${this.randNumValue}`)
    nameInput.value = this.userValue.name
    const emailInput = document.querySelector(`#user-email-input-${this.randNumValue}`)
    emailInput.value = this.userValue.email
    const phoneInput = document.querySelector(`#user-phone-input-${this.randNumValue}`)
    phoneInput.value = this.userValue.phone
    const statusInput = document.querySelector(`#user-status-input-${this.randNumValue}`)
    statusInput.checked = this.userValue.active

    searchResults.innerHTML = "";
  }
}
