import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['input', 'container', 'filePreview', 'imagePreview'];
  static values = { 
    previewImages: Boolean,
    ifValidEnableSubmit: Boolean,
    minFiles: Number,
    maxFiles: Number,
    validFormats: String
  }

  initialize() {
    this.inputId = this.inputTarget.id;
    this.validFormatsArray = this.inputTarget.dataset['accepts'].split(', ');
    this.submitBtn = document.querySelector('input[type="submit"]') || document.querySelector('button[type="submit"]');
  }
  
  styleDragover() {
    this.containerTarget.classList.add("is-dragging");
  }

  styleDrop() {
    this.containerTarget.classList.remove("is-dragging");
    setTimeout(() => {
      if (this.inputTarget.files[0]) {
        this.containerTarget.classList.add("is-dropped"); 
        return;
      }
      this.containerTarget.classList.remove("is-dropped"); 
    }, 150);
  }

  styleStopDrag() {
    this.containerTarget.classList.remove("is-dragging");
  }

  addFile() {
    const files = [...this.inputTarget.files];
    let hasLargeFile = false;
    let skipFileCheck = false;
    let isValid = false;

    super.dismissToast(this.inputId); // First dismiss existing toasts relating to file uploads

    this.clearFilePreview();
    
    if (this.checkMaxFileNumber(files.length) || this.checkMinFileNumber(files.length)) {
      this.cleanupInputAndRenderToast(this.numberValidationMsg);
      isValid = false;
      skipFileCheck = true;
      if (this.ifValidEnableSubmitValue) this.submitBtn?.setAttribute('disabled', 'disabled')
    }
    
    if (skipFileCheck) return; // Break out since max number or mandatory number of files validation failed.
    
    files.every((file) => {
      if (this.hasInvalidFile(file.type)) {
        isValid = false;
        this.cleanupInputAndRenderToast(`Only ${this.validFormatsValue} files can be uploaded. Please try again and upload only these file types.`)
        return false;
      }
      
      if (this.previewImagesValue) {
        this.renderImagePreview(file);
        this.element.classList.add('has-images');
      }

      this.renderFileName(file.name);
      
      hasLargeFile = this.checkAttachedFileSize(file);
      
      isValid = true;
      return true;
    });

    if (isValid && this.ifValidEnableSubmitValue) this.submitBtn?.removeAttribute('disabled');
    if (hasLargeFile) super.renderToast(
      this.inputId, 'Since a large file is attached, uploading may take a bit of time to complete. <br> Please be patient.', 'info'
      );
  }
    
  renderFileName(fileName) {
    this.filePreviewTarget.insertAdjacentHTML('beforeend', `<div>${fileName}</div>`)
  }

  renderImagePreview(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.imagePreviewTarget.insertAdjacentHTML('beforeend', `<img src='${e.target.result}'>`);
    };
    reader.readAsDataURL(file);
  }

  checkAttachedFileSize(file) {
    return file.size > 1000000;
  }

  checkMaxFileNumber(fileLength) {
    if (fileLength > this.maxFilesValue) {
      this.numberValidationMsg = `Exceeded max number of files that can be attached! Please try again with ${this.maxFilesValue} or less files attached.`;
      return true;
    };
  }

  checkMinFileNumber(fileLength) {
    if (this.minFilesValue > fileLength) {
      this.numberValidationMsg = `Attached less files than necessary! Please try again with ${this.minFilesValue} or more files attached.`;
      return true;
    }
  }

  clearInputValue() {
    this.inputTarget.value = ''; 
  }

  cleanupInputAndRenderToast(message) {
    super.renderToast(
      this.inputId, message
    );
    this.clearInputValue();
    this.clearFilePreview();
  }

  clearFilePreview() {
    this.filePreviewTarget.textContent = "";
    if (this.previewImagesValue) {
      this.element.classList.remove('has-images');
      this.imagePreviewTarget.textContent = "";
    }
  }
  
  hasInvalidFile(fileType) {
    let isInvalidFormat = true;

    //? Check if uploaded is Apple's heic file format and warn user to convert it.
    if (fileType == "image/heif" || fileType == "image/heic") {
      super.renderFlash(
        `Sorry for the inconvenience but Apple's HEIC images are not supported. 
          <a class="link external-link" title="Link to website for converting HEIC images" href="https://heic2jpeg.com/" target="_blank">Please click here</a> to convert the images to a JPEG then reupload them. Thanks!`, 
        "alert",
        "true"
      )
      setTimeout(() => {
        document.querySelector('.external-link').setAttribute("target", "_blank")
      }, 500);
      return isInvalidFormat;
    }

    //? Loop to check against component's valid formats
    this.validFormatsArray.forEach((format) => {
      if (format.includes(fileType)) {
        isInvalidFormat = false;
      } 
    })
    return isInvalidFormat;
  }
}
