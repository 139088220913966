import ApplicationController from "./application_controller";
import { ConfirmModal } from "../utils/swal.js";

export default class extends ApplicationController {
  static targets = ["selectedTrx"];

  // Called from marking Transaction resolved in Message_templates/index
  confirmTrxStatusToggle(e) {
    e.preventDefault();

    ConfirmModal.fire({
      title: "Are you sure?",
      html: "This will mark this transaction as Resolved.",
      icon: "warning",
      footer: "This is final and can only be reversed by the admin."
    }).then((result) => {
      if (result.value) {
        this.stimulate("ExpTransactionReflex#resolve");
        return;
      }
      setTimeout(() => {
        this.selectedTrxTarget.blur();
      }, 800);
    });
  }
}
