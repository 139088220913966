import Swal from 'sweetalert2/dist/sweetalert2.all.min.js'

export const Toast = Swal.mixin({
  toast: true,
  position: "bottom",
  showConfirmButton: false,
  timer: 3500,
  timerProgressBar: true,
  hideClass: {
    popup: "animate__animated animate__zoomOutDown",
  },
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const ConfirmModal = Swal.mixin({
  showCancelButton: true,
  showClass: { icon: "animate__animated animate__flip" },
  showCloseButton: true,
  confirmButtonText: "Yes",
  cancelButtonText: "No",
  cancelButtonColor: "",
  confirmButtonColor: "",
  reverseButtons: true,
});
