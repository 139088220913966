import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import { Toast } from "../utils/swal";
import DOMPurify from "dompurify";

/* This is your application's ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    document.querySelectorAll('a').forEach(l => { l.setAttribute("data-turbo-prefetch", "false")});
  }

  /* Application wide lifecycle methods.
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#demo"
   *
   *   error - error message from the server
   */

  unfocusTarget(target) {
    setTimeout(() => {
      target?.blur();
    }, 500);
  }

  showSuccessToast(message) {
    Toast.fire({
      icon: "success",
      customClass: { container: "toast-container success" },
      title: DOMPurify.sanitize(`${message}`),
    });
  }

  showDangerToast(message) {
    Toast.fire({
      icon: "error",
      customClass: { container: "toast-container danger" },
      title: DOMPurify.sanitize(`${message}`),
    });
  }

  beforeReflex(element, reflex) {
    // document.body.classList.add('wait')
  }

  reflexSuccess(element, reflex, error) {
    // show success message etc...
  }

  reflexError(element, reflex, error) {
    // show error message etc...
    this.showDangerToast(
      "There was some sort of error. Please refresh (F5) the page."
    );
  }

  afterReflex(element, reflex) {
    // document.body.classList.remove('wait')
  }
}
