import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  static targets = [
    "modal", "modalCard", "modalBackground"
  ];
  // TODO: Implement a close all option for all open modals 
  // ID is MOdals random generated ID in view component. 
  // Selector is the class or ID passed in the open modal button or link that corresponds with the VC, so like 'data-modal-selector-value=".choose-language-modal"'
  static values = {
    id: String,
    selector: String, 
    exitAnimation: String
  }

  showModal() {
    const modal = document.querySelector(this.selectorValue);
    document.body.setAttribute("modal-open", true);
    modal.classList.add('is-active');
  }

  closeModal() {
    if (this.hasModalTarget) { 
      closeModal(this.modalTarget, this.modalCardTarget, this.modalBackgroundTarget, this.exitAnimationValue);
      return;
    }
    closeModal();
  }
}

export const closeModal = (modal = ".modal",
                            modalCard = ".modal-card", 
                            modalBackground = ".modal-background",
                            modalExitAnimationClass = "animate__zoomOutDown",
                            timeoutMS = 580) => 
{
  // Check if function call passed in a selector instead of an element
  if (typeof(modal) === "string") modal = document.querySelector(`${modal}`) 
  if (typeof(modalCard) === "string") modalCard = document.querySelector(`${modalCard}`)
  if (typeof(modalBackground) === "string") modalBackground = document.querySelector(`${modalBackground}`) 
  modalCard.classList.add(modalExitAnimationClass);
  modalBackground.classList.add('delay-fade-out');
  setTimeout(() => {
    modal.classList.remove('is-active');
    modalCard.classList.remove(modalExitAnimationClass);
    modalBackground.classList.remove('delay-fade-out');
    document.body.removeAttribute("modal-open");
    if (modal.hasAttribute('data-modal-destroy-on-exit')) {
      modal.remove();
    }
  }, timeoutMS);
}