import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "messageContentInput"]

  insertPropertyValue(btn) {
    const btnTarget = btn.target;
    const textareaInput = this.messageContentInputTarget; 
    const propertyValue = btnTarget.dataset['propertyValue'];
    const expTransactionValue = btnTarget.dataset['expTransactionValue'];
    const cursorPosition = this.getCursorPosition(textareaInput);
    const insertValue = expTransactionValue ? expTransactionValue : propertyValue;

    if (textareaInput.setRangeText) {
      // setRangeText is supported
      textareaInput.setRangeText(insertValue, cursorPosition["start"], cursorPosition["end"], "end")
    } else {
      document.execCommand('insertText', false, insertValue);
    } 
    textareaInput.focus()
  }

  getCursorPosition(textarea) {
    if (textarea.selectionStart || textarea.selectionStart == '0') {
      return {'start': textarea.selectionStart, 'end': textarea.selectionEnd };
    }
    return { 'start': 0, 'end': 0 }; 
  }

}