import { Controller } from "stimulus"

export default class extends Controller {
  static values = { btnType: String, id: String }

  switchTab() {
    const tabContainerParent = document.getElementById(`tabs-container-${this.idValue}`);
    this.removeActiveClasses(tabContainerParent);
    this.addIsActive(this.btnTypeValue, tabContainerParent);
  }

  addIsActive(tabName, parentContainer) {
    const clickedTab = parentContainer.querySelector(`#${tabName}-tab-btn`);
    const clickedView = parentContainer.querySelector(`.${tabName}-container`);
    clickedTab.dataset['isActive'] = "true"; 
    clickedView.dataset['isOpen'] = "true"; 
  }

  removeActiveClasses(tabContainerParent) {
    const activeTab = tabContainerParent.querySelector('[data-is-active="true"]');
    const activeView = tabContainerParent.querySelector('[data-is-open="true"]');
    activeTab.dataset["isActive"] = "false";
    activeView.dataset["isOpen"] = "false";
  }
}