// Rendered from _flash_msg.html.erb partial on connect (right when partial is rendered) tp show 3.5s toast message.
import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = ["flashContainer", "flashMessage"];

  connect() {    
    const flash = this.flashMessageTarget;
    const flashMessage = flash.dataset["flashMessage"];
    const flashType = flash.dataset["flashType"];
    flashType == "notice"
      ? super.showSuccessToast(flashMessage)
      : super.showDangerToast(flashMessage)
  }
}
