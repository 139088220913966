import ApplicationController from "./application_controller";

export default class extends ApplicationController {
static targets = [ "detailsBtn" ]

  toggleIsOpenCSS() {
    const id = this.detailsBtnTarget.dataset["id"];
    const agentRowElement = document.getElementById(`transaction-row-${id}`);
    agentRowElement.classList.toggle("is-open")
  }
}