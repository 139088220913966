export function fetchModal(e, url) {  
  const main = document.querySelector('main')
  document.body.setAttribute("modal-open", true);
  fetch(url)
    .then((r) => r.text())
    .then((html) => {
      let fragment = document.createRange().createContextualFragment(html);
      main.appendChild(fragment);
    });
}
